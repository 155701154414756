import { cx } from 'class-variance-authority';
import { customAlphabet } from 'nanoid';
import { twMerge } from 'tailwind-merge/es5';

import { pluralize } from '@/lib/helpers';

export function cn(...inputs) {
  return twMerge(cx(inputs));
}

export const nanoid = customAlphabet(
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  7
);
export const connect = (id) => ({ connect: { id } });

export const connectIfSet = (id, onFalse) =>
  Boolean(id) ? connect(id) : onFalse;

export const disconnect = { disconnect: true };
export const toFormattedString = (minNum) => {
  const hours = Math.floor(minNum / 60) % 8;
  const days = Math.floor(minNum / (8 * 60));
  const minutes = Math.floor(minNum) % 60;
  const o = { d: days, h: hours, m: minutes };
  return Object.keys(o)
    .map((key) => renderString(o[key], key))
    .join(' ');
};
const keyLabel = {
  d: {
    one: ' Tag',
    other: ' Tage',
  },
  h: {
    one: ' Stunde',
    other: ' Stunden',
  },
  m: {
    one: ' Minute',
    other: ' Minuten',
  },
};
const renderString = (val, key) =>
  !!val ? `${renderVal(val)}${pluralize(val, keyLabel[key])}` : '';
const renderVal = (val) => (val < 10 ? `0${val}` : val);
