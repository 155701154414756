import { useMemo } from 'react';
import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

function createApolloClient(accessToken) {
  const httpLink = createUploadLink({
    uri:
      typeof window === 'undefined'
        ? process.env.ENDPOINT_PROXY_API
        : process.env.NEXT_PUBLIC_API_ENDPOINT, // Server URL (must be absolute)
    credentials: typeof window !== 'undefined' ? 'include' : null, // Additional fetch() options like `credentials` or `headers`
    headers: {
      'Apollo-Require-Preflight': 'true',
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    },
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    connectToDevTools: true,
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        allPresences: {
          merge: true,
        },
      },
    }),
    ssrForceFetchDelay: 100,
  });
}

export function initializeApollo(initialState = null, accessToken) {
  const _apolloClient = apolloClient ?? createApolloClient(accessToken);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo({ accessToken, ...pageProps }) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(
    () => initializeApollo(state, accessToken),
    [state, accessToken]
  );
  return store;
}
