import { startOfDay } from 'date-fns/startOfDay';

export const getFormattedDate = (date) => startOfDay(date).toISOString();

export const pluralize_de = (val) => {
  const n = Number(val),
    i = Math.floor(Math.abs(val)),
    v = val.toString().replace(/^[^.]*\.?/, '').length;
  if (isNaN(n)) throw Error('n is not a number');
  if (i === 1 && v === 0) return 'one';
  return 'other';
};

/**
 * Returns variant from the specified list of variants
 * according to the specified value and locale.
 *
 * @param {int} value
 * @param {object} variants
 *
 * @return {string | undefined}
 */
export const pluralize = (value, variants) => {
  if (!variants || 'object' !== typeof variants) {
    throw new Error('List of variants should be specified as a valid object');
  }

  const key = pluralize_de(value);

  return variants[key] || undefined;
};
