import Head from 'next/head';
import { ApolloProvider } from '@apollo/client';
import { SessionProvider } from 'next-auth/react';
import PropTypes from 'prop-types';

import 'focus-visible/dist/focus-visible';

import { useApollo } from '@/lib/apolloClient';

import 'react-datepicker/dist/react-datepicker.css';
import '@/styles/tailwind.css';
import '@/styles/index.scss';

import localFont from 'next/font/local';
import PlausibleProvider from 'next-plausible';

import { Toaster } from '@/elements/ui/toaster';
import { TailwindIndicator } from '@/components/TailwindIndicator';

const theinhardt = localFont({
  src: [
    {
      path: '../styles/fonts/Theinhardt-Light.woff2',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../styles/fonts/Theinhardt-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
  ],
  variable: '--font-theinhardt',
});

const domain =
  process.env.NODE_ENV === 'production' ? 'my.milkmonkey.de' : 'localhost:3000';

function MyApp({ Component, pageProps, err }) {
  const { session = {} } = pageProps || {};
  const apolloClient = useApollo({ ...session, ...pageProps });
  if (err) {
    console.group('Server Error');
    console.error('error', err);
    console.groupEnd();
  }

  return (
    <>
      <Head>
        <title>Milkmonkey Dashboard</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <PlausibleProvider
        domain={domain}
        enabled={domain.indexOf('localhost') !== -1 || undefined}
        trackLocalhost={domain.indexOf('localhost') !== -1}
        selfHosted={true}
        customDomain={'https://analytics.milkmonkey.de'}
        trackOutboundLinks={true}
        trackFileDownloads={true}
      >
        {/* eslint-disable-next-line react/no-unknown-property */}
        <ApolloProvider client={apolloClient}>
          <SessionProvider
            refetchInterval={5 * 60}
            refetchOnWindowFocus={true}
            session={pageProps.session}
          >
            <Component {...pageProps} err={err} />
          </SessionProvider>
        </ApolloProvider>
      </PlausibleProvider>

      <Toaster />
      <TailwindIndicator />
    </>
  );
}

MyApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any,
};
export default MyApp;
